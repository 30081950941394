<template>
    <div class="footers">
        <div class="footers-content">
            <div class="footers-left">
                <div class="footers-left-nav">
                    <!-- <span>友情链接</span>
					<span class="footers-left-nav-line">|</span> -->
                    <span @click="contact">联系我们</span>
                    <span class="footers-left-nav-line">|</span>
                    <span @click="concerning">关于学院</span>
                </div>
                <div class="footers-left-bottom"><span style="margin-right: 30px;" @click="copy">{{ copyright }}{{ beian
                        }}</span> 技术支持：{{ support
                    }}</div>
            </div>
            <img :src="public_code" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'footers',
    data() {
        return {
            name: "",//网站名称
            beian: '',//备案号
            support: '',//技术支持
            telephone: '',//电话
            website: '',//网站地址
            qqnumber: '',//QQ号
            email: '',//邮箱地址
            address: '',//学院详细地址
            notice: "",//公告通知
            jgurl: '',//机构登录跳转
            ksurl: '',//考生登录跳转地址
            logo: '',//官网LOGO
            public_code: '',//底部二维码
            keywords: '',//网站关键词
            description: '',//网址简介
            copyright: '',//网站版权所有
        }
    },
    created() {
        // 获取数据 初次渲染
        this.$request({
            url: '/api/common/webinfo',
            method: 'POST',
            data: {}
        }).then(res => {
            if (res.code == 1) {
                // console.log(res,'底部数据')
                let data = res.data
                this.beian = data.beian//备案号
                this.support = data.support//技术支持
                this.copyright = data.copyright//网站版权所有
                this.public_code = data.public_code//底部二维码
            }
        })
    },
    methods: {
        contact() {//联系我们
            this.$router.push({
                path: "/survey", //空页面路由
                query: {
                    index: 2
                }
            })
        },
        concerning() {//关于我们
            this.$router.push({
                path: "/survey", //空页面路由
                query: {
                    index: 0
                }
            })
        },
        copy() {//版权所有
            window.open('https://beian.miit.gov.cn/')
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
    .footers-content {
        width: 100%;
        padding: 0 5%;
    }
}

.footers {
    width: 100%;
    padding: 17px 0;
    background-color: #A4B3A9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footers-content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;

}

.footers-left {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.footers-left-nav {
    display: flex;
    align-items: center;
}

.footers-left-nav span:nth-child(2n-1) {
    cursor: pointer;
}

.footers-left-nav-line {
    padding: 0 12px;
}

.footers-left-bottom {
    padding-top: 30px;
    font-size: 14px;
    cursor: pointer;
}

.footers img {
    width: 116px;
    height: 116px;
}
</style>
