<template>
    <img src="@/assets/image/topImg.png" style="width: 100%; height: 400px"/>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>

</style>