<template>
	<div class="head">
		<div class="head-content" style="background-color: #A4B3A9;">
			<!-- 头部标题栏 -->
			<div class="head-title">
                <div class="" style="display: flex;line-height: 30px;">
                   <span>公告通知：</span>
                   <van-notice-bar scrollable :text="notice" />
                   <!-- <div id="node">
                       {{ notice }}
                   </div> -->
                </div>
				<div class="head-title-right">
                    <!-- <span class="head-title-right-login" @click="oldLogin">旧站登陆</span>
                    <span style="padding: 0 16px;">|</span> -->
					<span class="head-title-right-login" @click="jgLogin">机构登陆</span>
					<span style="padding: 0 16px;">|</span>
					<span class="head-title-right-login" @click="xyLogin">学员登陆</span>
					<span class="head-title-right-phone">咨询服务热线：{{ telephone }}</span>
				</div>
			</div>
		</div>
		<div class="head-content">
			<!-- 头部导航栏 -->
			<div class="head-nav">
				<img :src="logo" @click="backHome()"/>
				<div class="head-nav-list">
					<div class="head-nav-item" v-for="(item, index) in headList" :key="index" :class="headIndex == index ? 'head-nav-item-index' : ''"
					 @click="navto(item.path,index)" @mouseenter="enter(index, $event)" @mouseleave="leave">
						<span class="head-nav-item-m">{{item.title}}</span>
						<span class="head-nav-item-ms" :class="headIndex == index ? 'head-nav-item-index' : ''">{{item.title1}}</span>
						<span class="head-nav-item-ms" :class="headIndex == index ? 'head-nav-item-index' : ''">{{item.title2}}</span>
						<ul class="head-nav-item-menu" v-if="item.menu"
						 :style="{'height': childIndex == index ? childHeight+'px' : 0}">
							<li v-for="(menu, key) in item.menu" :key="key" @click.stop="navto(menu.path)">{{menu.title}}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'headers',
		props: {
			headIndex: {
				default: 0
			}
		},
		data() {
			return {
				headList: [
					{title: '首页', title1: 'HOME', title2: 'PAGE', path: '/index'},
					{title: '学院概况', title1: 'INSTITUTE', title2: 'OVERVIEW', path: '/survey'},
					{title: '教学机构', title1: 'TEACHING', title2: 'DEPARTMENT', path: '/mechanismIndex', menu: [
						{title: '成人部', path: '/mechanismIndex/mechanism'},
                        {title: '少儿部', path: '/mechanismIndex/shaoer'},
						{title: '学员作品', path: '/mechanismIndex/student'}
						
					]},
					{title: '艺术学会', title1: 'ART', title2: 'SOCIETY', path: '/society', menu: [
						{title: '学会介绍', path: '/society/introduce'}, 
                        {title: '会员风采', path: '/society/elegant'},
						{title: '学会活动', path: '/society/societyNew'}
					]},
					{title: '师资团队', title1: 'TEACHING', title2: 'TEAM', path: '/teachers'},
					{title: '美术考级', title1: 'ART GRADE', title2: 'EXAMINATION', path: '/finArt', menu: [
						{title: '考级简介', path: '/finArt/brief'}, {title: '考级指南', path: '/finArt/guide'},
						{title: '考级标准', path: '/finArt/standard'}
					]},
					{title: '新闻动态', title1: 'LATEST', title2: ' NEWS', path: '/news', menu: [
						{title: '新闻中心', path: '/news/newsCenter'},
						{title: '学院动态', path: '/news/newActive'}, {title: '展览中心', path: '/news/exhibition'}
					]},
				],
				// 子菜单状态
				childIndex: 0,
				childHeight: 0,
                name:"",//网站名称
                beian:'',//备案号
                support :'',//技术支持
                telephone:'',//电话
                website:'',//网站地址
                qqnumber:'',//QQ号
                email:'',//邮箱地址
                address:'',//学院详细地址
                notice:"",//公告通知
                jgurl:'',//机构登录跳转
                ksurl:'',//考生登录跳转地址
                logo:'',//官网LOGO
                public_code:'',//底部二维码
                keywords:'',//网站关键词
                description:'',//网址简介
                copyright:'',//网站版权所有
			}
		},
        created() {
            // 获取数据 初次渲染
            this.$request({
                url:'/api/common/webinfo',
                method:'POST',
                data:{}
            }).then(res=>{
                if(res.code==1){
                    // console.log(res,'头部数据')
                    let data = res.data
                    this.notice=data.notice//公告通知
                    this.telephone=data.telephone//咨询电话
                    this.jgurl=data.jgurl//机构登录
                    this.ksurl=data.ksurl//学员登录
                    this.logo = data.logo//logo
                    document.title=data.name//网站名称
                }
            })
        },
		methods: {
			navto(url,index){
				// console.log(index,'点击')
				this.$router.push(url)
                
			},
			// 鼠标移入
			enter(index,e) {
                // console.log(index,'鼠标移入')
				this.childIndex = index
				this.childHeight = e.target.childNodes[3].scrollHeight
			},
			// 鼠标移出
			leave() {
				this.childHeight = 0
			},
            move(){
               let width = document.getElementById('node').getBoundingClientRect().width 
            },
            jgLogin(){//机构登录
                 window.open(`${this.jgurl}`);
            },
            xyLogin(){//学员登录
                 window.open(`${this.ksurl}`);
            },
            backHome(){//返回首页
                this.$router.push({
                    path:"/index"
                })
            },
            oldLogin(){//旧站登陆
                window.open('https://old.szart.cn');
            }
		}
	}
</script>

<style lang="scss" scoped>
	.head{
		width: 100%;
	}
	.head-content{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@media screen and (max-width: 1200px) {
		.head-title{
			width: 100%;
		}
        .head-nav{
        	width: 100%;
        }
	}
	// 头部标题栏
	.head-title{
		width: 1200px;
		padding: 6px 0;
		display: flex;
		align-items: center;
		font-size: 16px;
		color: #FFFFFF;
		white-space: nowrap;
		justify-content: space-between;
	}
	
	.head-title-right{
		display: flex;
		align-items: center;
        padding-right: 5%;
	}
	.head-title-right-login:hover{
		color: #26716C;
		text-decoration: underline;
		cursor: pointer;
	}
	.head-title-right-phone{
		padding-left: 5%;
		white-space: nowrap;
	}
    #node{
        width: 200px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    .van-notice-bar{
        width: 240px;
        height: 30px !important;
        background-color: rgb(164, 179, 169) !important;
        font-size: 16px !important;
        padding: 0 !important;
        color: #fff !important;
        line-height: 30px !important;
    }
	// 头部导航栏
	.head-nav{
		width: 1200px;
		padding: 19px 0;
		display: flex;
		align-items: center;
        justify-content: space-between;
	}
    @media screen and (max-width: 1200px) {
        .head-nav{
        	width: 100%;
        }
        .head-nav img{
        	width: 20%;
        }
    }
	.head-nav img{
		height: 54px;
        cursor: pointer;
	}
	.head-nav-list{
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
	}
	.head-nav-item{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #272727;
		font-size: 16px;
		white-space: nowrap;
		position: relative;
		padding: 0 20px;
        // width: ;
	}
	.head-nav-item:hover, .head-nav-item:hover .head-nav-item-ms,.head-nav-item-index{
		color: #26716C !important;
		font-weight: 00;
		cursor: pointer;
	}
    .head-nav-item-m{
        line-height: 20px;
        padding-bottom: 5px;
    }
	.head-nav-item-ms{
		color: #999999;
		font-size: 12px;
        
	}
	// 下拉菜单
	.head-nav-item-menu{
		position: absolute;
		top: 70px;
		left: 0;
		width: 100%;
		z-index: 999;
		display: flex;
		flex-direction: column;
		padding: 0 5px;
		transition: all 0.5s;
		height: 0;
		overflow: hidden;
	}
	.head-nav-item-menu li{
		width: 100%;
		color: #272727;
		background: #ffffff;
		list-style: none;
		text-align: center;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		border-bottom: 1px solid rgba($color: #FFF, $alpha: 0.6);
		font-weight: 400;
	}
	.head-nav-item-menu li:hover{
		background: #F6F6EA;
	}
	.head-nav-item-menu li:last-child{
		border-bottom: none;
	}
</style>
